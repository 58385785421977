import './style.scss';
import { LinearProgress, styled } from '@mui/material';
import { useSelector } from 'react-redux';

const getVipLevel = (vipTiersData: any[], stakedAmount: number) => {
  const convertedVipTiersData = vipTiersData.map((vipTierData) => {
    return { ...vipTierData, amount: Number(vipTierData.amount) };
  });
  convertedVipTiersData.sort((a, b) => a.amount - b.amount);

  let currentVip = null;
  let nextVip = null;
  for (const convertedVipTierData of convertedVipTiersData) {
    if (stakedAmount >= convertedVipTierData.amount) {
      currentVip = convertedVipTierData;
    } else {
      nextVip = convertedVipTierData;
      break;
    }
  }

  return [currentVip, nextVip];
};

const CustomProgress = styled(LinearProgress)`
  background: #191b1e;
  height: 20px;
  border-radius: 100px;
  .MuiLinearProgress-bar {
    border-radius: 100px;
    background: #de6a2f;
  }
`;

// const glowList = [
//   { level: 'VIP 0', color: '#825C37' },
//   { level: 'VIP 1', color: '#825C37' },
//   { level: 'VIP 2', color: '#825C37' },
//   { level: 'VIP 3', color: '#825C37' },
//   { level: 'VIP 4', color: '#737373' },
//   { level: 'VIP 5', color: '#737373' },
//   { level: 'VIP 6', color: '#C7A571' },
//   { level: 'VIP 7', color: '#C7A571' },
//   { level: 'VIP 8', color: '#C98CFF' },
// ];

export const ToNextLevel: React.FC<{}> = () => {
  const { isLoggedIn } = useSelector<any, any>(({ profile }) => profile);
  const { allPoolInfo, vipTiersData } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);
  const stakedAmount = allPoolInfo?.amount ?? 0;

  const [, nextVip] = getVipLevel(vipTiersData, stakedAmount);

  return (
    <div className='flex flex-col items-center md:items-start p-6 rounded-2xl border-[#45454F] border-[1px] bg-[#2B2B31]'>
      <img
        className='mb-2 w-[54px] h-[62px]'
        src={nextVip && isLoggedIn ? nextVip.imgXl : '/assets/imgs/tier-system-xl/default.png'}
        alt='next vip level'
      />
      <div className='title text-[#A2A2AF] mb-4'>Stake SPO to next level</div>
      <div className='relative w-full'>
        <CustomProgress variant='determinate' value={!!nextVip ? (stakedAmount * 100) / nextVip?.amount : 100} />
        {nextVip && (
          <div
            className='absolute inset-0 text-sm font-bold text-center text-white'
            style={{ filter: 'drop-shadow(0px 1px 1px #000000)' }}
          >
            {isLoggedIn ? `${stakedAmount.toLocaleString()}/${nextVip?.amount.toLocaleString()}` : '-/-'}
          </div>
        )}
      </div>
    </div>
  );
};
