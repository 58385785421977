import { useSelector } from 'react-redux';
import { VipTier } from './vip-tier';
import classNames from './vip-tiers.module.scss';

export const VipTiers: React.FC<{}> = () => {
  const { vipTiersData } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);

  return (
    <div className={`grid grid-cols-10 gap-4 rounded-box`}>
      <hr className={classNames.boxHeaderBorder} />
      <div className='col-span-2'>
        <div className={classNames.box_firstRow}>
          <h1 className='text-2xl font-bold'>VIP TIERS</h1>
        </div>

        <div className={classNames.box_row}>Amount</div>
        {/* <div className={classNames.box_row}>Multiplier allocation</div> */}
        <div className={classNames.box_row}>Pool weight</div>
      </div>
      {vipTiersData.map((vipTierData: any, index: number) => (
        <VipTier key={index} vipTierData={vipTierData} />
      ))}
    </div>
  );
};
