import React from 'react';

export const usePopover = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const onClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  return [Boolean(anchorEl), anchorEl, onClick, onClose];
};
