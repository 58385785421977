import { createSlice } from '@reduxjs/toolkit';
import { login } from 'utils/auth';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    signIn: (state, { payload }) => {
      state = { ...state, ...payload, isLoggedIn: true };
      localStorage.setItem('profile', JSON.stringify(state));
      login(payload);
      return state;
    },
    signOut: (state) => {
      state = { isLoggedIn: false };
      localStorage.clear();
      setTimeout(() => document.location.reload(), 0);
      return state;
    },
  },
});

export const { signIn, signOut } = profileSlice.actions;

export default profileSlice.reducer;
