import {
  erc20Contract,
  flexibleStakingContract,
  web3,
  standardStakingContract,
  ferrumStakingContract,
} from 'contracts/contract';
import BigNumber from 'bignumber.js';

export const getPolygonFee = (args: any) => {
  const { chainId } = args;
  if (chainId === 80001)
    return fetch('https://gasstation-mumbai.matic.today/v2')
      .then((response) => response.json())
      .then((data) => data.fast.maxFee * 1e9);
  if (chainId === 137)
    return fetch('https://gasstation-mainnet.matic.network/v2')
      .then((response) => response.json())
      .then((data) => data.fast.maxFee * 1e9);
};

export const getSymbol = async (tokenAddress: string) => {
  const symbol = tokenAddress ? await erc20Contract(tokenAddress).methods.symbol().call() : 'ETH';
  return symbol;
};

export const getDecimals = async (tokenAddress: string) => {
  const decimals = tokenAddress ? await erc20Contract(tokenAddress).methods.decimals().call() : 18;
  return decimals;
};

export const getBalance = async (tokenAddress: string, walletAddress: string) => {
  const balance = tokenAddress
    ? await erc20Contract(tokenAddress).methods.balanceOf(walletAddress).call()
    : await web3.eth.getBalance(walletAddress);
  return balance;
};

export const getCurrentBalance = async (tokenAddress: string, walletAddress: string) => {
  const [balance, decimals] = await Promise.all([getBalance(tokenAddress, walletAddress), getDecimals(tokenAddress)]);

  const balanceInRoundedNumber = new BigNumber(balance);
  return balanceInRoundedNumber.div(10 ** decimals).toNumber();
};

export const getUserInfo = async (poolId: string, address: string, contractAddress: string) => {
  const userInfo = await flexibleStakingContract(contractAddress).methods.userInfo(poolId, address).call();
  return userInfo;
};

export const stake = async (
  tokenAddress: string,
  contactAddress: string,
  walletAddress: string,
  pId: string,
  amount: number,
) => {
  const decimals = await getDecimals(tokenAddress);
  const stakeAmount = new BigNumber(amount).times(10 ** decimals).toFixed();

  const res = await flexibleStakingContract(contactAddress)
    .methods.stake(pId, stakeAmount)
    .send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });

  return res.transactionHash;
};

export const standardPoolStake = async (
  tokenAddress: string,
  contactAddress: string,
  walletAddress: string,
  amount: number,
) => {
  const decimals = await getDecimals(tokenAddress);
  const stakeAmount = new BigNumber(amount).times(10 ** decimals).toFixed();

  const res = await standardStakingContract(contactAddress).methods.stake(stakeAmount).send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });

  return res.transactionHash;
};

export const standardPoolUnstake = async (contactAddress: string, walletAddress: string) => {
  const res = await standardStakingContract(contactAddress).methods.unstake().send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });
  return res.transactionHash;
};

export const ferrumPoolUnstake = async (
  contactAddress: string,
  walletAddress: string,
  amount: number,
  decimal: number,
) => {
  const unstakeAmount = new BigNumber(amount).times(10 ** decimal).toFixed();
  const res = await ferrumStakingContract(contactAddress).methods.withdraw(unstakeAmount).send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });
  return res.transactionHash;
};

export const unstake = async (
  tokenAddress: string,
  contactAddress: string,
  walletAddress: string,
  pId: string,
  amount: number,
) => {
  const decimals = await getDecimals(tokenAddress);
  const uhstakeAmount = new BigNumber(amount).times(10 ** decimals).toFixed();

  const res = await flexibleStakingContract(contactAddress)
    .methods.unStake(pId, uhstakeAmount)
    .send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });

  return res.transactionHash;
};

export const pendingReward = async (contactAddress: string, pId: string, walletAddress: string) => {
  return await flexibleStakingContract(contactAddress).methods.pendingReward(pId, walletAddress).call();
};

export const standardPoolPendingReward = async (contactAddress: string, walletAddress: string) => {
  return await standardStakingContract(contactAddress).methods.pendingReward(walletAddress).call();
};

export const standardPoolUserStakeAmount = async (contactAddress: string, walletAddress: string) => {
  return await standardStakingContract(contactAddress).methods.userStakeAmount(walletAddress).call();
};

export const ferrumPoolUserStakeAmount = async (contactAddress: string, walletAddress: string) => {
  return await ferrumStakingContract(contactAddress).methods.stakeOf(walletAddress).call();
};

export const standardPoolTotalStaked = async (contactAddress: string) => {
  return await standardStakingContract(contactAddress).methods.totalStaked().call();
};

export const userInfo = async (contactAddress: string, pId: string, walletAddress: string) => {
  return await flexibleStakingContract(contactAddress).methods.userInfo(pId, walletAddress).call();
};

export const allowanceAndApprove = async (
  paymentTokenAddress: string,
  contactAddress: string,
  walletAddress: string,
  amountsValue: number,
) => {
  try {
    const isETH = paymentTokenAddress === '0x0000000000000000000000000000000000000000';
    if (isETH) return false;

    const [allowance, decimals] = await Promise.all([
      erc20Contract(paymentTokenAddress).methods.allowance(walletAddress, contactAddress).call(),
      getDecimals(paymentTokenAddress),
    ]);
    const amountWithBg = new BigNumber(amountsValue).times(10 ** decimals);
    if (amountWithBg.gt(new BigNumber(allowance))) {
      await erc20Contract(paymentTokenAddress)
        .methods.approve(contactAddress, amountWithBg.toFixed())
        .send({ from: walletAddress, gas: 500000, gasPrice: '4000000000' });
    }
    return true;
  } catch (err) {
    console.error('allowanceAndApprove', err);
    throw err;
  }
};

export * from './ferrumPoolStake';
export * from './ferrumPoolTotalStaked';
