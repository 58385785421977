import { AppController, AppTheme } from "containers";

export const withTheme = (Component) => (props) =>
  (
    <AppTheme>
      <AppController>
        <Component {...props} />
      </AppController>
    </AppTheme>
  );
