import { createSlice } from '@reduxjs/toolkit';

const networkInfoSlice = createSlice({
  name: 'networkInfoSlice',
  initialState: {
    networkInfo: {},
  },
  reducers: {
    updateNetworkInfoData: (state, { payload }) => {
      state = { ...state, ...payload };
      return state;
    },
  },
});

export const { updateNetworkInfoData } = networkInfoSlice.actions;

export default networkInfoSlice.reducer;
