import {
  Button,
  styled,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Tooltip,
} from '@mui/material';
import { EPoolType, PoolStatus, PoolType } from 'helpers';
import './style.scss';
import { DateTime } from 'luxon';
import { isFinished, isStarted, shortenNumber } from 'utils';
import { useMemo } from 'react';

const HeadCell = styled(TableCell)`
  background: #36363f;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #45454f;
  padding: 16px 12px;
  font-weight: 500;
  color: #fff;
  font-size: 14px;
`;

const BodyCell = styled(TableCell)`
  background: #2b2b31;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #45454f;
  padding: 12px;
  font-size: 16px;
`;

const BodyRow = styled(TableRow)`
  :hover {
    td {
      background: #45454f;
    }
  }
`;

const CustomTable = styled(Table)`
  tr:last-child {
    td {
      border: none;
    }
  }
`;

const StatusTag = ({
  closeAt,
  openAt,
  status,
}: {
  closeAt: string;
  openAt: string;
  status: PoolStatus
}) => {
  const isFinish = isFinished(closeAt);
  const isStart = isStarted(openAt);
  const color = useMemo(() => {
    if (status !== PoolStatus.Open) {
      return '#F53C5B'
    }
    else if (isStart) {
      return isFinish ? '#F53C5B' : '#51DE3A'
    } else {
      return '#FFB938'
    }
  }, [status, isStart, isFinish])
  const text = useMemo(() => {
    switch (status) {
      case PoolStatus.Filled:
        return 'Filled'
      case PoolStatus.Closed:
        return 'Closed'
      case PoolStatus.Open:
        if (isStart) {
          return isFinish ? 'Closed' : 'Open'
        } else {
          return 'Coming Soon'
        }
    }
  }, [status, isStart, isFinish])
  const tillStartTime = isStart
    ? 0
    : Math.ceil(
      DateTime.fromISO(openAt, { zone: 'utc' }).diff(DateTime.now(), [
        'days',
      ]).days,
    );

  return (
    <div className="flex flex-col">
      {!!closeAt && (
        <>
          <div className="text-white flex items-center gap-1">
            <span
              className="h-2 w-2 rounded-full inline-block"
              style={{ background: color }}
            ></span>{' '}
            {text}
          </div>
          {/* {status === PoolStatus.Open && isStart && !isFinish && (
            <div className="text-[#8B8B9B] text-sm">{`${remainTime} days left`}</div>
          )} */}
          {status === PoolStatus.Open && !isStart && (
            <div className="text-[#8B8B9B] text-sm">
              in {`${tillStartTime} days`}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const TableList: React.FC<{
  poolsList: PoolType[];
  setChosenIndex: (index: number) => void;
  setIsOpenPopupFlexible: (open: boolean) => void;
  setIsOpenPopupStandard: (open: boolean) => void;
  setIsOpenPopupPrivate: (open: boolean) => void;
  isHasFooter: boolean;
}> = ({
  poolsList,
  setChosenIndex,
  setIsOpenPopupFlexible,
  setIsOpenPopupStandard,
  setIsOpenPopupPrivate,
  isHasFooter,
}) => {
    return (
      <CustomTable>
        <TableHead>
          <TableRow>
            <HeadCell width={"25%"}>Pool name</HeadCell>
            <HeadCell className="text-center" width={"20%"}>Est. APR</HeadCell>
            <HeadCell width={"15%"}>Min. locked day</HeadCell>
            <HeadCell width={"20%"}>Staked / Staking Cap</HeadCell>
            <HeadCell className='text-right' >Status</HeadCell>
            <HeadCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {poolsList?.map((row: PoolType, index: number) => (
            <BodyRow key={index}>
              <BodyCell>
                <div className="w-44">
                  <Tooltip placement="top" title={<div>{row.pool_name}</div>}>
                    <div
                      className="text-white font-bold text-ellipsis overflow-hidden break-word"
                      style={{
                        WebkitLineClamp: 2,
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {row.pool_name}
                    </div>
                  </Tooltip>
                </div>
              </BodyCell>
              <BodyCell>
                <div>
                  {row.est_apr?.length === 1 ? (
                    <div className="font-bold text-[#51DE3A] text-center">{`${row.est_apr[0].value}%`}</div>
                  ) : (
                    <div className="flex gap-2 justify-center">
                      {row.est_apr?.map((item, ind) => (
                        <div
                          className="text-center bg-[#36363F] rounded-lg py-1 px-4"
                          style={{ border: '1px solid #45454F' }}
                          key={ind}
                        >
                          <div className="font-bold text-[#51DE3A]">{`${item.value}%`}</div>
                          <div className="text-[#B9B9C3] text-sm">{`${item.time} days`}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </BodyCell>
              <BodyCell className="text-[#B9B9C3]">
                {row.est_apr?.length === 0
                  ? '-'
                  : `${row.est_apr?.sort((a, b) => a.time - b.time)?.[0]?.time
                  } days`}
              </BodyCell>
              <BodyCell className="text-white">
                <div className="max-w-[240px]">
                  {(row.status === PoolStatus.Filled || row.status === PoolStatus.Closed)
                    ? `${shortenNumber(row.staking_cap ?? 0).toLocaleString()} / ${shortenNumber(row.staking_cap ?? 0).toLocaleString()} ${row.token_name}` :
                    row.staking_cap === -1
                      ? 'Unlimited'
                      : `${shortenNumber(row.stakedAmount ?? 0)} / ${shortenNumber(row.staking_cap ?? 0).toLocaleString()} ${row.token_name}`}
                </div>
              </BodyCell>
              <BodyCell className="text-white">
                <StatusTag
                  closeAt={row.close_at}
                  openAt={row.start_at}
                  status={row.status}
                />
              </BodyCell>
              <BodyCell className="text-white w-[180px]">
                {row.status !== PoolStatus.Filled
                      && row.status !== PoolStatus.Closed
                      && !isFinished(row.close_at)
                      && isStarted(row.start_at)
                      ? (
                        <Button
                          className="w-full"
                          onClick={() => {
                            setChosenIndex(index);
                            if (row.type === EPoolType.Flexible) {
                              setIsOpenPopupFlexible(true);
                            } else {
                              setIsOpenPopupStandard(true);
                            }
                          }}
                        >
                          <span className="block lg:hidden">Stake</span>
                          <span className="lg:block hidden">Stake now</span>
                        </Button>
                      )
                      : (
                        <Button
                          variant="outlined"
                          fullWidth
                          className="border-[#747486]"
                          onClick={() => {
                            setChosenIndex(index);
                            if (row.type === EPoolType.Flexible) {
                              setIsOpenPopupFlexible(true);
                            } else {
                              setIsOpenPopupStandard(true);
                            }
                          }}
                        >
                          Check
                        </Button>
                      )}
              </BodyCell>
            </BodyRow>
          ))}
          {isHasFooter === true && (
            <BodyRow>
            <BodyCell colSpan={5}>
              If you have staked $SPO before, please visit Spores Staking Pool V1.
            </BodyCell>
            <BodyCell className="text-white">
              <Button
                variant="outlined"
                fullWidth
                className="border-[#747486]"
                onClick={() => {
                  document.location.href = '/v1';
                }}
              >
                Check
              </Button>
            </BodyCell>
          </BodyRow>
          )}
          
        </TableBody>
      </CustomTable>
    );
  };
