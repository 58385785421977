import { Dialog } from '@mui/material';
import './change-network-panels.scss';
import { SwitchNetworkPanels } from '../switch-network-panels';
import usePopup from 'hooks/Popup';

export const ChangeNetworkPanels: React.FC<{ rpcInfo: any, setIsShowChangeNetworkNo: any }> = ({ rpcInfo, setIsShowChangeNetworkNo }) => {
  //Dialog
  const [isOpenCart, onOpenCart, onCloseCart] = usePopup(false);

  console.log('data', rpcInfo);

  async function changeNetworkFunc(event: any) {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: rpcInfo?.chainId,
            rpcUrls: rpcInfo?.rpcUrls,
            chainName: rpcInfo?.name,
            nativeCurrency: rpcInfo?.nativeCurrency,
            blockExplorerUrls: rpcInfo?.explorer,
          },
        ],
      });
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      if(chainId === rpcInfo?.chainId){
        setIsShowChangeNetworkNo(1);
      }
    } catch (error) {
      console.log(error)
      onOpenCart();
    } 
  }
  return (
    <>
      <div className='text-white rounded-lg bg-[#1E2940]'>
        <div className='p-2 rounded-lg mt-6 bg-[#1E2940] text-[#D0D4DC] gap-16'>
          <div className='flex flex-col justify-start'>
            <div className='mb-4 mt-4 flex items-center'>
              <span className='ml-2'>Please click here to change the RPC on Metamask for the best experience.</span>
              <button
              className='change-network-button ml-2'
              onClick={changeNetworkFunc}
              >
              <span className='text-[#EECE7C]'>
                Change
              </span>
            </button>
            </div>
          </div>
        </div>
      </div>
      <Dialog open={isOpenCart} maxWidth="xs" onClose={onCloseCart}>
        <SwitchNetworkPanels
          rpcInfo={rpcInfo}
          onClosePopup={onCloseCart}
          setIsShowChangeNetworkNo={setIsShowChangeNetworkNo}
        />
      </Dialog>
    </>
  );
};
