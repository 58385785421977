import { store } from "reducers";
import { signIn } from "reducers/profileSlice";
import { connectApi, loginApi } from "services/account";
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi';
import { bsc, bscTestnet } from 'viem/chains';
import { isNotNil } from "ramda";
import { watchConnections, reconnect, disconnect, signMessage, getAccount } from '@wagmi/core';

// 1. Define constants
const projectId = 'c9ce17ad7531e60b4eed5232dc01958d'

// 2. Create wagmiConfig
const metadata = {
  name: 'Spores Launchpad',
  description: 'Spores Launchpad',
  url: 'https://launchpad.spores.app/', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bsc, bscTestnet]
export const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableInjected: false, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: false, // Optional - true by default
  enableWalletConnect: true, // Optional - true by default
})
reconnect(config);

// 3. Create modal
export const modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: false, // Optional - defaults to your Cloud configuration
  allWallets: 'SHOW',
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0',
  ],
  themeVariables: {
    '--w3m-z-index': 1400,
  }
})

export const signAccount = async (callback) => {
  let addr = getAccount(config).address;
  if(isNotNil(addr)) addr = addr.toLowerCase();

   try {

    let { nonce } = await connectApi(addr);
    nonce = nonce + "";
    const message = `Welcome to Spores Launchpad!\n\nClick \"Sign\" to sign in. No password needed!\n\nWallet address:\n${addr}\n\nNonce:\n${nonce}`;
    const rawSig = await signMessage(config, { message});
    const splitAt = rawSig.length - 2;

    // for Ethereum, last bytes of the signature must be 1b or 1c
    // Metamask did this automatically but for hardware wallet like Trezor or Ledger,
    // this must be done manually
    let v = rawSig.slice(-2);
    if (v === "00") {
      v = "1b";
    } else if (v === "01") {
      v = "1c";
    }
    const signature = rawSig.substring(0, splitAt) + v;

    const { token } = await loginApi({
      addr,
      nonce,
      signature,
    });
    store.dispatch(signIn({ address: addr, token }));

    if (typeof callback === "function") callback();
    modal.close();
  } catch (error) {
    console.log('connectWallet', JSON.stringify(error))
  }
}

export const connectWallet = async (callback) => {

  let addr = getAccount(config).address;
  if(isNotNil(addr)) addr = addr.toLowerCase();
  
  disconnect(config);
  modal.open();

  watchConnections(config, {
    onChange(data) {
      signAccount();
    },
  })

  // try {

  //   let { nonce } = await connectApi(addr);
  //   nonce = nonce + "";
  //   const message = `Welcome to Spores Launchpad!\n\nClick \"Sign\" to sign in. No password needed!\n\nWallet address:\n${addr}\n\nNonce:\n${nonce}`;
  //   const rawSig = await signMessage(config, { message});
  //   const splitAt = rawSig.length - 2;

  //   // for Ethereum, last bytes of the signature must be 1b or 1c
  //   // Metamask did this automatically but for hardware wallet like Trezor or Ledger,
  //   // this must be done manually
  //   let v = rawSig.slice(-2);
  //   if (v === "00") {
  //     v = "1b";
  //   } else if (v === "01") {
  //     v = "1c";
  //   }
  //   const signature = rawSig.substring(0, splitAt) + v;

  //   const { token } = await loginApi({
  //     addr,
  //     nonce,
  //     signature,
  //   });
  //   store.dispatch(signIn({ address: addr, token }));

  //   if (typeof callback === "function") callback();
  //   modal.close();
  // } catch (error) {
  //   console.log('connectWallet', JSON.stringify(error))
  // }

  // const { isLoggedIn } = store.getState().profile;
  // if (isLoggedIn) {
  //   if (typeof callback === "function") callback();
  // } else {
  //   try {
  //     let { nonce } = await connect(addr);
  //     nonce = nonce + "";
  //     const message = `Welcome to Spores Launchpad!\n\nClick \"Sign\" to sign in. No password needed!\n\nWallet address:\n${addr}\n\nNonce:\n${nonce}`;
  //     const rawSig = await signMessage(config, { message});
  //     const splitAt = rawSig.length - 2;

  //     // for Ethereum, last bytes of the signature must be 1b or 1c
  //     // Metamask did this automatically but for hardware wallet like Trezor or Ledger,
  //     // this must be done manually
  //     let v = rawSig.slice(-2);
  //     if (v === "00") {
  //       v = "1b";
  //     } else if (v === "01") {
  //       v = "1c";
  //     }
  //     const signature = rawSig.substring(0, splitAt) + v;

  //     const { token } = await login({
  //       addr,
  //       nonce,
  //       signature,
  //     });
  //     store.dispatch(signIn({ address: addr, token }));

  //     if (typeof callback === "function") callback();
  //   } catch (error) {
  //     console.log('connectWallet', JSON.stringify(error))
  //   }
  // }
};