import { useState } from "react";
import {
  FAQs,
  MyVipLevel,
  ToNextLevel,
  TotalStaked,
  VipTiers,
  VipTiersMobile,
  OpenPoolsTable,
  ClosePoolsTable,
} from "./components";
import "./style.scss";
import { Switch } from "@mui/material";

export const Home = () => {
  const [isToggled, setIsToggled] = useState(false);

  return (
    <>
      {/* <TopPanel /> */}
      <div className="page-container container mx-auto py-6 gap-[60px] flex flex-col px-4">
        <div className="lg:grid hidden" id="StakeUnstacke">
          <VipTiers />
        </div>
        <div className="flex lg:hidden">
          <VipTiersMobile />
        </div>
        <div className="">
          <div className="mb-4 flex justify-between items-center">
            <span className="text-white font-bold text-2xl">STAKING DEFI</span>
            <div className="flex items-center">
            <label className="inline-flex items-center mb-5 cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" onClick={() => setIsToggled(!isToggled)}/>
              <div className="relative w-[2.5rem] h-[1.45rem] bg-[rgba(255, 255, 255, 0)] border border-[#555F7B] rounded-2xl peer-focus:outline-none border-[#555F7B] peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#EECE7C]"></div>
              <span className="ml-2 font-sans text-lg font-bold">My pools</span>
            </label>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
            <TotalStaked />
            <MyVipLevel />
            <ToNextLevel />
          </div>
          <div className="text-white font-bold text-2xl mb-4">ACTIVE POOLS</div>
          <OpenPoolsTable isMyPools={isToggled}/>
          <div className="text-white font-bold text-2xl mb-4 mt-8">
            CLOSED POOLS
          </div>
          <ClosePoolsTable isMyPools={isToggled}/>
        </div>
        <div className="">
          <FAQs />
        </div>
      </div>
    </>
  );
};
