import { Close, Schedule } from '@mui/icons-material';
import { Divider, IconButton, styled, Tab, Tabs } from '@mui/material';
import { PoolType } from 'helpers';
import { PrivateUnstakePanel } from '../stake-unstake/components';
import './style.scss';
import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { earlyRewardReturn, isFinished, maturityReturn } from 'utils';

const CustomTabs = styled(Tabs)`
  padding: 4px;
  margin-bottom: 16px;
  background: #2b2b31;
  border: 1px solid #45454f;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTab-root {
    width: 50%;
    font-weight: 500 !important;
    font-size: 14px !important;
    color: #737382 !important;
    text-transform: none !important;
    padding: 4px 12px !important;
    min-height: auto !important;
    line-height: 28px;
  }
  .MuiTab-root.Mui-selected {
    color: #fff !important;
    background: #36363f;
    box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0d0d0d;
    border-radius: 8px;
  }
  .MuiTabs-scroller {
    overflow: visible !important;
  }
`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div className='mb-4'>{children}</div>}
    </div>
  );
}

const InfoLine = ({ title, value, icon, color }: { title: string; value: string; icon?: boolean; color?: string }) => (
  <div className='flex justify-between text-sm'>
    <div className='text-[#A2A2AF] flex gap-2 items-center'>
      {icon && <Schedule className='w-[14px] h-[14px] text-[#2E90FA]' />}
      {title}
    </div>
    <div className='font-bold' style={{ color: color ?? 'inherit' }}>
      {value}
    </div>
  </div>
);

export const PopupPrivatePool = ({ handleClose, poolData }: { handleClose: () => void; poolData: PoolType }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className='text-white rounded-2xl bg-[#2B2B31] w-full'>
      <div
        className='bg-[#36363F] px-8 py-4 flex justify-between items-center'
        style={{ borderBottom: '1px solid #45454F' }}
      >
        <div className='font-bold text-xl'>{poolData.pool_name}</div>
        <IconButton onClick={handleClose}>
          <Close className='text-[#B9B9C3]' />
        </IconButton>
      </div>
      <div className='flex flex-col md:flex-row'>
        <div className='px-8 py-6 text-sm flex-1' style={{ borderRight: '1px solid #45454F' }}>
          <PrivateUnstakePanel poolData={poolData} />
        </div>
        <div className='px-8 py-6 text-sm w-[358px]'>
          <div className='font-bold mb-1'>Pool Information</div>
          <CustomTabs value={value} onChange={handleChange}>
            <Tab disableRipple label={`${earlyRewardReturn(poolData?.est_apr)?.time} days`} />
            {poolData?.est_apr?.length > 1 && (
              <Tab disableRipple label={`${maturityReturn(poolData?.est_apr)?.time} days`} />
            )}
          </CustomTabs>
          <TabPanel value={value} index={0}>
            <InfoLine title='Est. APR:' value={`${earlyRewardReturn(poolData?.est_apr)?.value}%`} color='#51DE3A' />
          </TabPanel>
          {poolData?.est_apr?.length > 1 && (
            <TabPanel value={value} index={1}>
              <InfoLine title='Est. APR:' value={`${maturityReturn(poolData?.est_apr)?.value}%`} color='#51DE3A' />
            </TabPanel>
          )}
          <Divider className='border-[#45454F] mb-4' />
          <div className='flex flex-col gap-4'>
            <div>
              <InfoLine
                title='Start time'
                value={DateTime.fromISO(poolData?.start_at, { zone: 'utc' }).toFormat('dd MMM yyyy')}
                icon
              />
              <Divider className='border-[#2E90FA] h-4 w-2' orientation='vertical' flexItem />
              <InfoLine
                title='Close time'
                value={DateTime.fromISO(poolData?.close_at, { zone: 'utc' }).toFormat('dd MMM yyyy')}
                icon
              />
              <Divider className='border-[#2E90FA] h-4 w-2' orientation='vertical' flexItem />
              {value === 0 ? (
                <InfoLine
                  title={`Early withdraw (${poolData?.est_apr?.[0]?.time} days)`}
                  value={DateTime.fromISO(poolData?.close_at, { zone: 'utc' })
                    .plus({ days: poolData?.est_apr?.[0]?.time })
                    .toFormat('dd MMM yyyy')}
                  icon
                />
              ) : (
                <InfoLine
                  title={`Maturity reward (${maturityReturn(poolData?.est_apr)?.time} days)`}
                  value={DateTime.fromISO(poolData?.close_at, { zone: 'utc' })
                    .plus({
                      days: maturityReturn(poolData?.est_apr)?.time,
                    })
                    .toFormat('dd MMM yyyy')}
                  icon
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
