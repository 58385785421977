import './ScrollingText.scss';

export const ScrollingText = ({ text1, text2 }) => {
  return (
    <div className='gif-container'>
      <div className='invisible'>{text1?.length > text2?.length ? text1 : text2}</div>
      <div className='gif g1'>{text1}</div>
      <div className='gif g2'>{text2}</div>
    </div>
  );
};
