// import axios from "axios";
import { USERNAME, ACCESS_TOKEN } from './helpers/keys';

const saveToLocalStorage = (data: any) => {
  !!data.username && localStorage.setItem(USERNAME, data.username);
  localStorage.setItem(ACCESS_TOKEN, data.accessToken || data.token);
};

// export const login = (data: any) =>
//   axios
//     .post("/api/login", data)
//     .then((saveToLocalStorage)
//     .catch((error) => {
//       console.log(error);
//     });

export const login = (data: any) =>
  new Promise((resolve, reject) => {
    saveToLocalStorage(data);
    resolve(undefined);
  });
