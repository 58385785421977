import BigNumber from 'bignumber.js';
import { ferrumStakingContract } from 'contracts/contract';
import { getDecimals } from '.';

export const ferrumPoolStake = async (
  tokenAddress: string,
  contactAddress: string,
  walletAddress: string,
  amount: number,
) => {
  const decimals = await getDecimals(tokenAddress);
  const stakeAmount = new BigNumber(amount).times(10 ** decimals).toFixed();

  const res = await ferrumStakingContract(contactAddress).methods.stake(stakeAmount).send({ from: walletAddress, gas: 500000, gasPrice: '4000000000'});

  return res.transactionHash;
};
