import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAlert } from "hooks";
import jwtDecode from "jwt-decode";

import { signIn } from "reducers/profileSlice";
import { connectProvider } from 'utils/wallet';

const Controller = ({ children }) => {
  useAlert();

  const dispatch = useDispatch();
  const [isReady, setIsReady] = React.useState(false);

  useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem("profile"));
      if (profile) {
        jwtDecode(profile.token);
        dispatch(signIn(profile));

        // Should call after check previous session from access token
        connectProvider();
      }
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsReady(true);
    }
  }, [dispatch]);

  return isReady && children;
};

export default Controller;
