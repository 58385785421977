export const DEPRECATED_POOL = [
  {
    "id": "b29fb791-2f4f-4bf9-8761-e165f62d4be8",
    "pool_name": "SPO-BSC Traditional pool Aug 2022",
    "type": 2,
    "start_at": "2022-08-09T14:00:00",
    "close_at": "2022-09-08T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "undefined",
    "token_name": "SPO",
    "token_address": "undefined",
    "status": 2,
    "contract_address": "0xF4E698a9A3bFb3f90aF5fcb3e3b2d642130693E8",
    "pool_index": -1,
    "details_url": "https://staking-aug-2022.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.786456"
  },
  {
    "id": "674194ba-8310-4dc1-99b1-fc8032292391",
    "pool_name": "SPO-BSC Medium Term 3",
    "type": 2,
    "start_at": "2022-08-11T14:00:00",
    "close_at": "2022-08-26T14:00:00",
    "est_apr": [
      {
        "time": 90,
        "value": 30
      },
      {
        "time": 180,
        "value": 100
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x547Ab7e2C03E0bf5d9EBEf4f5355f5588A60FD46",
    "pool_index": -1,
    "details_url": "https://mid-term-100-apr.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.90336"
  },
  {
    "id": "a0869a4a-5054-44df-bf38-d261d759d384",
    "pool_name": "SPO-BSC Traditional pool Sept 2022",
    "type": 2,
    "start_at": "2022-09-08T14:00:00",
    "close_at": "2022-10-08T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xa3Aaf5e15502712C86034E8542CE3e1963AD09AC",
    "pool_index": -1,
    "details_url": "https://mid-term-100-apr.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:32.023389"
  },
  {
    "id": "2f34482d-54ca-4fa4-bd5c-b9c3c280b1b0",
    "pool_name": "SPO-BSC Short Term",
    "type": 2,
    "start_at": "2021-10-16T14:00:00",
    "close_at": "2021-10-31T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 15
      },
      {
        "time": 60,
        "value": 50
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x359460cbb04c95eaaab495d4fa05ef026e22968e",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x359460cbb04c95eaaab495d4fa05ef026e22968e",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.258355"
  },
  {
    "id": "99818fd5-1b6e-4b01-95ee-13d384c86bad",
    "pool_name": "SPO-BSC Short Term 2",
    "type": 2,
    "start_at": "2021-11-05T14:00:00",
    "close_at": "2021-11-20T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 15
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x2dee9be3dc7ac958d37ab07f889423f91ad5f7b9",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x2dee9be3dc7ac958d37ab07f889423f91ad5f7b9",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.4907"
  },
  {
    "id": "59cc9f26-9619-4ea7-9fb9-96e4d418e607",
    "pool_name": "SPO-BSC Short Term 3",
    "type": 2,
    "start_at": "2021-11-20T14:00:00",
    "close_at": "2022-12-10T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xf4e35ec9095aec4f107331e10c0dec67cea7b6d3",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0xf4e35ec9095aec4f107331e10c0dec67cea7b6d3/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.610931"
  },
  {
    "id": "c2a4ce86-1ea4-46ac-9905-d6aa370134f4",
    "pool_name": "SPO-BSC Medium Term 1",
    "type": 2,
    "start_at": "2021-11-27T14:00:00",
    "close_at": "2022-12-12T14:00:00",
    "est_apr": [
      {
        "time": 90,
        "value": 30
      },
      {
        "time": 180,
        "value": 80
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x85b01309605090eebf53fafe9a47afc40aa21bc9",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x85b01309605090eebf53fafe9a47afc40aa21bc9/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.730692"
  },
  {
    "id": "6a77d9a8-d29b-49a7-ba75-345dd51cdbca",
    "pool_name": "SPO-BSC Short Term 6",
    "type": 2,
    "start_at": "2022-02-17T14:00:00",
    "close_at": "2022-03-09T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x93889f5744889d85739cebd3ab9352e1ee55c1aa",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x93889f5744889d85739cebd3ab9352e1ee55c1aa/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.197374"
  },
  {
    "id": "aa6acf62-fe02-4b6a-ba87-425d2a064cee",
    "pool_name": "SPO-BSC Short Term 7",
    "type": 2,
    "start_at": "2022-03-11T14:00:00",
    "close_at": "2022-04-10T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x39ee59835770873005b59980ee7883f67be8741a",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x39ee59835770873005b59980ee7883f67be8741a/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.313018"
  },
  {
    "id": "0301a5e7-5177-4ac5-9247-8d3d32ed0264",
    "pool_name": "Short Term LP",
    "type": 2,
    "start_at": "2021-10-19T14:00:00",
    "close_at": "2021-11-03T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 15
      },
      {
        "time": 60,
        "value": 50
      }
    ],
    "staking_cap": 100000000,
    "network": "ethereum",
    "token_name": "UNI-V2",
    "token_address": "0x6fcc7db7acbc3e9dde3ee7d96cb61fc9c0ca17a7",
    "status": 2,
    "contract_address": "0x5304504e7f3bb9ecef93838da2e4ecfc67aaaf95",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesliq/info/0x5304504e7f3bb9ecef93838da2e4ecfc67aaaf95",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.375747"
  },
  {
    "id": "98f3d092-7243-456a-bd40-9da8ca5677ad",
    "pool_name": "SPO-BSC Short Term 8",
    "type": 2,
    "start_at": "2022-04-09T14:00:00",
    "close_at": "2022-05-09T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xb67b6b85fb2f35f5979134c2e83a0c33cab2cfa5",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0xb67b6b85fb2f35f5979134c2e83a0c33cab2cfa5/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.429975"
  },
  {
    "id": "d49aceff-32ac-48b3-9ab1-52fa716fce5c",
    "pool_name": "SPO-BSC Traditional Pool May 2022",
    "type": 2,
    "start_at": "2022-05-19T14:00:00",
    "close_at": "2022-06-18T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x696ca545032A16B5B4bF2A9C0715604D4d391569",
    "pool_index": -1,
    "details_url": "https://staking-traditional-may2022.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.546352"
  },
  {
    "id": "5c6aaddf-b682-48aa-b209-824d5754bbef",
    "pool_name": "SPO-BSC Short Term 5",
    "type": 2,
    "start_at": "2022-01-05T14:00:00",
    "close_at": "2022-01-25T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x62b142f75ae9f2b8ca64e39a6c0388bcba3e1840",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0x62b142f75ae9f2b8ca64e39a6c0388bcba3e1840/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.964277"
  },
  {
    "id": "853bc8e2-8a9c-46c4-80e0-fbcf3c0460c7",
    "pool_name": "SPO-BSC Medium Term 2",
    "type": 2,
    "start_at": "2022-01-19T14:00:00",
    "close_at": "2022-02-08T14:00:00",
    "est_apr": [
      {
        "time": 90,
        "value": 30
      },
      {
        "time": 180,
        "value": 90
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xfe8551e955e25674c2c1cfe0836bfae782730136",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0xfe8551e955e25674c2c1cfe0836bfae782730136/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.081008"
  },
  {
    "id": "b1a32c00-5de7-40a1-b215-cfacc20b0a74",
    "pool_name": "SPO Medium Term 4",
    "type": 2,
    "start_at": "2023-03-06T14:00:00",
    "close_at": "2023-03-21T14:00:00",
    "est_apr": [
      {
        "time": 90,
        "value": 30
      },
      {
        "time": 180,
        "value": 150
      }
    ],
    "staking_cap": 500000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 1,
    "contract_address": "0xF03c1b5e26717AF8633f061B5107268AA565DBb3",
    "pool_index": -1,
    "details_url": "https://medium-term-4.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:31.081008",
  },
  {
    "id": "dfec6f67-d89c-4c98-9c69-f972ee4b3dde",
    "pool_name": "SPO-ETH",
    "type": 2,
    "start_at": "2023-03-06T14:00:00",
    "close_at": "2023-03-06T14:00:00",
    "est_apr": [],
    "staking_cap": -1,
    "network": "ethereum",
    "token_name": "UNI-V2",
    "token_address": "0x6fcc7db7acbc3e9dde3ee7d96cb61fc9c0ca17a7",
    "status": 2,
    "contract_address": "",
    "pool_index": -1,
    "details_url": "https://lp.staking.spores.app/spores/staking",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:19.833603"
  },
  {
    "id": "abff0077-c244-4e02-9ece-d9e2aeeac744",
    "pool_name": "Short Term LP",
    "type": 2,
    "start_at": "2022-10-16T14:00:00",
    "close_at": "2022-10-26T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 15
      },
      {
        "time": 60,
        "value": 50
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "Cake-LP",
    "token_address": "0xad1661bd7175997171970ef65c4392a181a5fbb5",
    "status": 2,
    "contract_address": "0x6a9a739503bb05a9d4bd8396811cc79edb1b911a",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbscliq/info/0x6a9a739503bb05a9d4bd8396811cc79edb1b911a",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.140697"
  },
  {
    "id": "6f0d677b-9b1a-43ad-86cc-cc97c6be735c",
    "pool_name": "SPO-BSC Traditional pool Oct 2022",
    "type": 2,
    "start_at": "2022-10-11T14:00:00",
    "close_at": "2022-11-11T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0x3d9d0e3B00F2DFe94B6cAfb01580a46bb69dAB87",
    "pool_index": -1,
    "details_url": "https://staking-oct-2022.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:32.143474"
  },
  {
    "id": "778044a7-b429-40a4-bd62-4d4e6ed729ce",
    "pool_name": "SPO-BSC Short Term 4",
    "type": 2,
    "start_at": "2021-12-14T14:00:00",
    "close_at": "2022-01-03T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xefbd4b2a0a885765dcf2cc87574bc946e994b51f",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/sporesbsc/info/0xefbd4b2a0a885765dcf2cc87574bc946e994b51f/BSC",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.847078"
  },
  {
    "id": "4a29897e-1bd7-4199-a240-ce8d181d4982",
    "pool_name": "SPO-BSC Traditional pool Nov 2022",
    "type": 2,
    "start_at": "2022-11-10T14:00:00",
    "close_at": "2022-12-10T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 2,
    "contract_address": "0xDC6f6825BD2671ADA7df2EfE9927CdA28DC70f7f",
    "pool_index": -1,
    "details_url": "https://staking-nov-2022.spores.app/",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:32.262639"
  },
  {
    "id": "01e3fc99-cdad-4180-9f22-019340cc3069",
    "pool_name": "Short Term 1",
    "type": 2,
    "start_at": "2022-09-21T14:00:00",
    "close_at": "2022-10-01T14:00:00",
    "est_apr": [
      {
        "time": 30,
        "value": 8
      },
      {
        "time": 90,
        "value": 30
      }
    ],
    "staking_cap": 100000000,
    "network": "ethereum",
    "token_name": "SPO",
    "token_address": "0xcbe771323587ea16dacb6016e269d7f08a7acc4e",
    "status": 2,
    "contract_address": "0xed87a23c1bd9f0568f8bf0e6c075568a19e3923f",
    "pool_index": -1,
    "details_url": "https://stake.unifyre.io/spores/info/0xed87a23c1bd9f0568f8bf0e6c075568a19e3923f",
    "created_at": "2023-03-22T03:47:14.011264",
    "updated_at": "2023-03-22T03:47:14.011264",
    "deletedAt": "2023-03-22T03:53:30.021143"
  },
  {
    "id": "9ec3723b-d42f-4bba-8054-109bb0a9b424",
    "pool_name": "Traditional Pool Apr 2023",
    "type": 1,
    "start_at": "2023-03-31T10:00:00",
    "close_at": "2023-04-15T10:00:00",
    "est_apr": [
      {
        "time": 45,
        "value": 12
      },
      {
        "time": 90,
        "value": 25
      }
    ],
    "staking_cap": 300000000,
    "network": "bnb_chain",
    "token_name": "SPO",
    "token_address": "0x8357c604c5533fa0053beaaa1494da552cea38f7",
    "status": 1,
    "contract_address": "0x0338Ef6473386C01e30855b28FB8167000174Ee4",
    "pool_index": -1,
    "details_url": "https://docs.google.com/forms/d/e/1FAIpQLSdCqTcx-H0LUjJPaI0bhvdNCOxEJKazPewmosBOCH6Wyy7I4w/viewform",
    "created_at": "2023-03-31T10:14:40.75761",
    "updated_at": "2023-03-31T10:14:40.75761",
    "deletedAt": "2023-08-22T07:48:19.559713"
  }
]