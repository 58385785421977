import { DeprecatedPoolsTable } from './components';
import './style.scss';

export const HomeV1 = () => {
  return (
    <>
      {/* <TopPanel /> */}
      <div className='page-container container mx-auto py-6 gap-[60px] flex flex-col px-4'>
        <div className=''>
          <div className='text-white font-bold text-2xl mb-4'>STAKING DEFI</div>
          <div className='grid grid-cols-1 md:grid-cols-3 gap-6 mb-6'>
          </div>
          <DeprecatedPoolsTable />
        </div>
      </div>
    </>
  );
};
