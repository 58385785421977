import { Outlet } from 'react-router-dom';

import { AppFooter, AppHeader } from 'containers';

import styles from './PrivateLayout.module.scss';

export const PrivateLayout = () => {
  return (
    <main className='min-h-screen flex flex-col'>
      <AppHeader />
      <div className={styles.contentContainer}>
        <Outlet />
      </div>
      <AppFooter />
    </main>
  );
};
