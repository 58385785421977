import { IconButton, LinearProgress, styled, Tooltip } from '@mui/material';
import BigNumber from 'bignumber.js';
import { ScrollingText } from 'components';
import { ferrumPoolUserStakeAmount, getDecimals, standardPoolUserStakeAmount, userInfo } from 'contracts/helpers';
import { EPoolType, PoolType } from 'helpers';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'reducers/allPoolInfoAction';

const CustomProgress = styled(LinearProgress)`
  background: #191b1e;
  height: 8px;
  border-radius: 100px;
  .MuiLinearProgress-bar {
    border-radius: 100px;
    background: #de6a2f;
  }
`;

const getVipLevel = (vipTiersData: any[], stakedAmount: number) => {
  const convertedVipTiersData = vipTiersData.map((vipTierData) => {
    return { ...vipTierData, amount: Number(vipTierData.amount) };
  });
  convertedVipTiersData.sort((a, b) => a.amount - b.amount);

  let currentVip = null;
  let nextVip = null;
  for (const convertedVipTierData of convertedVipTiersData) {
    if (stakedAmount >= convertedVipTierData.amount) {
      currentVip = convertedVipTierData;
    } else {
      nextVip = convertedVipTierData;
      break;
    }
  }

  return [currentVip, nextVip];
};

export const PoolsInfo: React.FC<{ poolData?: PoolType; ref?: any }> = forwardRef(({ poolData }, ref) => {
  const { isLoggedIn, address: walletAddress } = useSelector<any, any>(({ profile }) => profile);
  const { vipTiersData, allPoolInfo } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);
  const [currentVip, nextVip] = getVipLevel(vipTiersData, allPoolInfo?.amount ?? 0);
  const [stakedAmount, setStakedAmount] = useState<number>(0);

  const getCurrentStaking = useCallback(async () => {
    if (!isLoggedIn || !poolData) return;
    try {
      const decimal = await getDecimals(poolData?.token_address);
      let res;
      if (poolData?.type === EPoolType.Standard) {
        res = await standardPoolUserStakeAmount(poolData?.contract_address, walletAddress);
      } else if (poolData?.type === EPoolType.Flexible) {
        res = await userInfo(poolData?.contract_address, poolData?.pool_index?.toString(), walletAddress);
        res = res?.amount;
      } else {
        res = await ferrumPoolUserStakeAmount(poolData?.contract_address, walletAddress);
      }
      const customizeRes = new BigNumber(res).div(10 ** decimal).toNumber();
      setStakedAmount(customizeRes);
    } catch (e) {
      console.log(e);
    }
  }, [isLoggedIn, poolData, walletAddress]);

  useEffect(() => {
    getCurrentStaking();
  }, [getCurrentStaking]);

  useImperativeHandle(
    ref,
    () => ({
      reload: getCurrentStaking,
    }),
    [getCurrentStaking],
  );

  return (
    <>
      <div className='flex justify-between mb-4'>
        <div className='text-sm'>Current staking</div>
        <div className='text-white font-bold'>
          {isLoggedIn ? `${stakedAmount.toLocaleString()} ${poolData?.token_name}` : '-'}
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <div className='flex gap-2 items-center'>
          <div className='text-sm'>Current level</div>
          <Tooltip placement='top' title={<div>Reload</div>}>
            <IconButton className='hover:bg-[#36363F] rounded-lg px-2 py-1' onClick={getUserData}>
              <img src='/assets/imgs/xl-icons/icon-reload.png' className='w-3 h-3' />
            </IconButton>
          </Tooltip>
        </div>
        <div className='text-white font-bold flex gap-2 items-center'>
          <img
            className='w-auto h-6'
            src={currentVip ? currentVip.imgXl : '/assets/imgs/tier-system-xl/default.png'}
            alt='vip level'
          />
          {currentVip?.name ?? '-'}
        </div>
      </div>
      <div>
        <CustomProgress
          variant='determinate'
          value={!!nextVip ? ((allPoolInfo?.amount ?? 0) * 100) / nextVip?.amount : 100}
        />
      </div>
      <div className='flex justify-between mb-4 text-sm'>
        <div>{`Next: ${isLoggedIn && nextVip ? nextVip?.name : '-'}`}</div>
        <div className='text-right'>
          <ScrollingText
            text1={
              isLoggedIn
                ? `${Number(allPoolInfo?.amount ?? 0).toLocaleString()}/${(nextVip?.amount ?? 0).toLocaleString()}`
                : '-/-'
            }
            text2={
              isLoggedIn
                ? `${((nextVip?.amount ?? 0) - Number(allPoolInfo?.amount ?? 0)).toLocaleString()} SPO left`
                : '- SPO left'
            }
          />
        </div>
      </div>
    </>
  );
});
