import { ACTIVE_POOL } from 'env';
import { store } from 'reducers';
import { getStakingApi } from 'swagger';
import { updateAllPoolInfo, updateAllPoolInfoData } from './allPoolInfoSlice';
// import privateSummerSale from '../data/private_summer_sale.json'

export const getData = async () => {
  try {
    const { data: dataVipTier } = await getStakingApi()
      .apiStakingPidVipStakingGet({
        pid: ACTIVE_POOL,
      })
      .then((res) => res.json());
    store.dispatch(
      updateAllPoolInfoData({
        vipTiersData: dataVipTier?.filter((item) => item.name !== 'VIP 0'),
      }),
    );
  } catch (e) {
    console.log(e);
  }
};

export const getUserData = async () => {
  const { isLoggedIn } = store.getState().profile;
  try {
    if (!isLoggedIn) {
      store.dispatch(updateAllPoolInfo({}));
    } else {
      const { data: dataAllPool } = await getStakingApi()
        .apiStakingUserStatusGet()
        .then((res) => res.json());

      // const privateSummerSaleInfo = privateSummerSale.find((item) => (item?.buyer_wallet + '').toLowerCase() === dataAllPool.walletAddress.toLowerCase())
      // if (!!privateSummerSaleInfo)
      //   dataAllPool.amount += privateSummerSaleInfo.SPO_Amount

      store.dispatch(updateAllPoolInfo(dataAllPool));
    }
  } catch (e) {
    console.log(e);
  }
};
