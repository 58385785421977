import { Button, Paper } from '@mui/material';
import './switch-network-panels.scss';

export const SwitchNetworkInfoPanels = ({ onChangeNetwork, onClosePopup, children }: any) => {
  return (
    <Paper className='warning-paper'>
      <div className='flex flex-col items-center gap-3'>
        {children}
          <img
            src='/assets/imgs/icons/warning.png'
            width={52}
            height={52}
            alt='warning-logo'
          />
          <div className='reject-text'>
            <span className='justify-center text-white'>Rejected</span>
          </div>
          <span className='user-warning-text'>
            You have chosen to reject the network change. For the best experience, we suggest you change the network on
            Metamask
          </span>
      </div>
      <div className='w-full h-full flex flex-col items-center gap-3'>
        <Button
          className='change-nw-button gap-10 items-center justify-center flex w-full h-full'
          onClick={onChangeNetwork}
        >
          Change Network
        </Button>
        <a className='text-[#E8E8EB] items-between justify-center' href="#17" onClick={() => onClosePopup()}>Later</a>
      </div>
    </Paper>
  );
};

export const SwitchNetworkPanels: React.FC<{ rpcInfo: any, onClosePopup: any, setIsShowChangeNetworkNo: any }> = ({
  rpcInfo,
  onClosePopup,
  setIsShowChangeNetworkNo
}) => {
  async function onChangeNetwork(event: any) {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: rpcInfo?.chainId,
            rpcUrls: rpcInfo?.rpcUrls,
            chainName: rpcInfo?.name,
            nativeCurrency: rpcInfo?.nativeCurrency,
            blockExplorerUrls: rpcInfo?.explorer,
          },
        ],
      });

      window.ethereum?.on('chainChanged', () => {
        onClosePopup();
        setIsShowChangeNetworkNo(1);
      });
    } catch (error) {
      // onClosePopup();
    } 
  }

  return (
    <>
      <SwitchNetworkInfoPanels onChangeNetwork={onChangeNetwork} onClosePopup={onClosePopup}/>
    </>
  );
};