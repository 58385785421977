import { useSelector } from 'react-redux';
import { VipTierMobile } from './vip-tier-mobile';

export const VipTiersMobile: React.FC<{}> = () => {
  const { vipTiersData } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);

  return (
    <div className='w-full'>
      <div className='grid grid-cols-3 text-sm rounded-box w-auto m-0'>
        <div className='flex justify-center font-bold'>VIP TIERS</div>
        <div className='flex justify-center'>Amount</div>
        <div className='flex justify-center'>Pool weight</div>
      </div>
      {vipTiersData.map((vipTierData: any, index: number) => (
        <VipTierMobile key={index} vipTierData={vipTierData} />
      ))}
    </div>
  );
};
