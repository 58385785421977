import { formatNumber } from 'utils';

export const VipTierMobile: React.FC<{
  vipTierData: any;
}> = ({ vipTierData = {} }) => (
  <div>
    <div className='grid grid-cols-3 px-7 py-4 border-b border-slate-800 text-sm'>
      <div className='flex items-center justify-center'>
        <span className='font-bold uppercase'>{vipTierData.name}</span>
      </div>
      <div className='flex items-center justify-center'>{formatNumber(vipTierData.amount)}</div>

      <div className='flex items-center justify-center'>{formatNumber(vipTierData.pool_weight_min)}</div>
    </div>
  </div>
);
