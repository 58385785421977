import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  IconButton,
  Paper,
  styled,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  allowanceAndApprove,
  ferrumPoolStake,
  getCurrentBalance,
  stake,
  standardPoolStake,
} from 'contracts/helpers';
import usePopup from 'hooks/Popup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { connectWallet } from 'reducers/profileAction';
import { getStakingApi } from 'swagger';
import { formatNumber, isFinished, isStarted } from 'utils';
import PopupInfo from './popupInfo';
import { Close, Done, CancelOutlined } from '@mui/icons-material';
import { PoolsInfo } from './pools-info';
import { EPoolType, PoolType } from 'helpers';
import { getUserData } from 'reducers/allPoolInfoAction';
import { ChangeNetworkPanels } from '../../change-netwok-panels';
import { getNetworkData } from 'reducers/networkInfoAction';

const CustomDialog = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: transparent;
    background-size: 100% 100%;
    width: 450px;
    @media (max-width: 800px) {
      width: 300px;
      padding: 34px 27px 34px 27px;
    }
  }
`;

const StakingStage: React.FC<{
  title: any;
  description: any;
  loading: any;
  success: any;
  error: any;
  handleRetry: any;
}> = ({ title, description, loading, success, error, handleRetry }) => {
  return (
    <div className="flex gap-10 mb-4 md:mb-6">
      <div>
        {!loading && !success && !error && (
          <CircularProgress
            variant="determinate"
            sx={{ color: '#1C0B02' }}
            thickness={4}
            value={100}
            style={{ fontSize: '30px !important' }}
          />
        )}
        {loading && (
          <CircularProgress
            sx={{ color: '#F9B300', fontSize: 30 }}
            thickness={3}
          />
        )}
        {success && <Done sx={{ color: '#6FAF51', fontSize: 40 }} />}
        {error && <Close sx={{ color: '#FF613F', fontSize: 40 }} />}
      </div>
      <div className="flex flex-col gap-1">
        <div className="text-xl text-left font-white">{title}</div>
        <div
          className="text-[#8B8B9B] text-xs md:text-base"
          style={{ fontSize: 15 }}
        >
          {description}
        </div>
        {error && (
          <Button
            variant="contained"
            className="w-20 text-xs px-3 mt-4 rounded-full text-[#E8E8EB] bg-[#36363F]"
            onClick={handleRetry}
          >
            Try again
          </Button>
        )}
      </div>
    </div>
  );
};

export const StakePanel: React.FC<{
  classNames: any;
  poolData?: PoolType;
  handleSuccess?: () => void;
  remainPoolSize?: number;
}> = ({ classNames, poolData, handleSuccess, remainPoolSize }) => {
  const poolsInfoRef = useRef<any>();
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [balance, setBalance] = useState<number>(0);
  const [isFill, setIsFill] = useState(true);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isApprovedFailed, setIsApprovedFailed] = useState<boolean>(false);
  const [isApprovedLoading, setIsApprovedLoading] = useState<boolean>(false);
  const [isStaked, setIsStaked] = useState<boolean>(false);
  const [isStakedFailed, setIsStakedFailed] = useState<boolean>(false);
  const [isStakedLoading, setIsStakedLoading] = useState<boolean>(false);
  const [openPopupStake, setOpenPopupStake] = useState<boolean>(false);

  const { isLoggedIn, address: walletAddress } = useSelector<any, any>(
    ({ profile }) => profile,
  );
  const isStandardPool = useMemo(
    () => poolData?.type === EPoolType.Standard,
    [poolData?.type],
  );
  const isFlexiblePool = useMemo(
    () => poolData?.type === EPoolType.Flexible,
    [poolData?.type],
  );
  const [loading, setLoading] = useState<boolean>(false);

  const maxNumber = useMemo(
    () =>
      Math.floor(
        !remainPoolSize || remainPoolSize === -1
          ? balance
          : remainPoolSize > balance
          ? balance
          : remainPoolSize,
      ),
    [balance, remainPoolSize],
  );

  const isInvalid = useMemo(
    () => !!(amount && amount > maxNumber),
    [amount, maxNumber],
  );
  const isStart = isStarted(poolData?.start_at??'');
  const isFinish = isFinished(poolData?.close_at??'');

  const { networkInfo } = useSelector(({ networkInfoSlice }) => networkInfoSlice);

  const netwokAddToMetaMask = {
    name: networkInfo.chainName,
    chainName: networkInfo.chainName,
    isTestNet: networkInfo.isTestnet,
    chainId: networkInfo.chainId,
    rpcUrls: [networkInfo.rpc],
    blockExplorerUrls: [networkInfo.explorer],
    nativeCurrency: {
      name: networkInfo.symbol,
      symbol: networkInfo.symbol,
      decimals: networkInfo.decimal,
    },
  };

  useEffect(() => {
    getNetworkData();
  }, []);
  const getBalance = useCallback(async () => {
    try {
      if (!isLoggedIn) return;
      if (!poolData) return;
      const res = await getCurrentBalance(
        poolData?.token_address,
        walletAddress,
      );
      setBalance(res);
    } catch (e) {
      console.log(e);
    }
  }, [isLoggedIn, poolData, walletAddress]);

  useEffect(() => {
    getBalance();
  }, [getBalance]);

  const approveToken = async (tokenAmount: number) => {
    try {
      setIsApprovedFailed(false);
      setIsApprovedLoading(true);
      const approved = await allowanceAndApprove(
        poolData?.token_address ?? '',
        poolData?.contract_address ?? '',
        walletAddress,
        tokenAmount,
      );
      setIsApproved(approved);
      return true;
    } catch (error) {
      console.log('error', error);
      setIsApprovedFailed(true);
      return false;
    } finally {
      setIsApprovedLoading(false);
    }
  };

  const stakeToken = async (tokenAmount: number) => {
    try {
      setIsStakedFailed(false);
      setIsStakedLoading(true);
      let tx_hash = '';
      if (isStandardPool) {
        tx_hash = await standardPoolStake(
          poolData?.token_address ?? '',
          poolData?.contract_address ?? '',
          walletAddress,
          tokenAmount,
        );
      } else if (isFlexiblePool) {
        tx_hash = await stake(
          poolData?.token_address ?? '',
          poolData?.contract_address ?? '',
          walletAddress,
          poolData?.pool_index?.toString() ?? '',
          tokenAmount,
        );
      } else {
        tx_hash = await ferrumPoolStake(
          poolData?.token_address ?? '',
          poolData?.contract_address ?? '',
          walletAddress,
          tokenAmount,
        );
      }

      await getStakingApi().apiStakingPidStakePost(
        {
          pid: poolData?.pool_index?.toString() ?? '',
        },
        {
          amount: tokenAmount,
          tx_hash,
        },
      );
      setIsStaked(true);
    } catch (error) {
      console.log('error', error);
      setIsStakedFailed(true);
    } finally {
      setIsStakedLoading(false);
    }
    setLoading(false);
  };

  const onStake = async () => {
    if (!isLoggedIn || !poolData) return;
    if (!amount) {
      setIsFill(false);
    } else {
      setIsFill(true);
      if (amount > balance) return;

      setOpenPopupStake(true);
      const isOk = await approveToken(amount);
      if (!isOk) return;

      stakeToken(amount);
    }
  };

  const [isOpenInfo, , onCloseInfo] = usePopup(false);
  const [isShowChangeNetworkNo, setIsShowChangeNetworkNo] = useState(0);

  const onClosePopDone = () => {
    if (handleSuccess) {
      handleSuccess();
    }
    getBalance();
    getUserData();
    setOpenPopupStake(false);
    setIsApproved(false);
    setIsStaked(false);
    setIsApprovedFailed(false);
    setIsStakedFailed(false);
    poolsInfoRef.current?.reload?.();
  };
  return (
    <div>
      <div className="text-[#A2A2AF]">
        <div className="text-sm font-bold mb-2">Enter amount</div>
        <div
          className={`flex justify-between gap-2 pl-3 p-1 text-white ${
            classNames.numberInput
          } ${isInvalid || !isFill ? classNames.numberInputHasError : ''}`}
        >
          <NumberFormat
            disabled={!isLoggedIn}
            value={amount}
            onValueChange={({ floatValue: value }) => {
              if (value !== undefined) {
                setIsFill(true);
              }
              setAmount(value);
            }}
            maxLength={9}
            allowNegative={false}
            decimalScale={0}
            className="text-start bg-transparent text-lg font-bold numberinput--placeholder w-full"
          />
          <div className="">
            <button
              disabled={balance <= 0}
              className={classNames.buttonMax}
              onClick={() => setAmount(maxNumber)}
            >
              Max
            </button>
          </div>
        </div>
        {isInvalid && (
          <span className="text-[#F53C5B]">
            Oops…Maximum available is {formatNumber(maxNumber)}{' '}
            {poolData?.token_name}
          </span>
        )}
        {!isFill && <span className="text-[#F53C5B]">Please enter amount</span>}
        <div className="flex justify-between items-center mb-4">
          <div>
            Balance:{' '}
            <span className="text-white font-bold">
              {isLoggedIn
                ? `${formatNumber(balance, '', 0)} ${poolData?.token_name}`
                : '-'}
            </span>
          </div>
          <a
            href="https://pancakeswap.finance/swap?outputCurrency=0x8357c604c5533fa0053beaaa1494da552cea38f7"
            target="_blank"
            className="text-[#EECE7C] font-bold lg:font-normal leading-[40px]"
          >
            Get SPO
          </a>
        </div>
        <PoolsInfo ref={poolsInfoRef} poolData={poolData} />
        <div className="">
          {!isLoggedIn ? (
            <Button fullWidth onClick={connectWallet}>
              Connect wallet
            </Button>
          ) : (
            <LoadingButton
              fullWidth
              className="rounded-lg py-3 leading-5"
              onClick={onStake}
              disabled={isInvalid || !isStart || isFinish}
              loading={loading}
              variant="contained"
            >
              Stake now
            </LoadingButton>
          )}
        </div>
      </div>
      {/* {isOpenDone && (
        <Dialog open={true} onClose={onClosePopDone} maxWidth="xs">
          <StakeConfirmPopup onClose={onClosePopDone} amount={amount} />
        </Dialog>
      )} */}
      {isOpenInfo && (
        <Dialog open={isOpenInfo} onClose={onCloseInfo} maxWidth="lg">
          <PopupInfo />
        </Dialog>
      )}
      {openPopupStake && (
        <>
        <CustomDialog fullWidth open={openPopupStake}>
          <div className="text-white lg:p-10" style={{borderRadius: '8px', backgroundColor: '#232328'}}>
            <div className="relative">
              <div className="font-skadi font-bold text-3xl text-left font-white mb-2">
                STAKING
              </div>
              <Divider
                className="mb-4 md:mb-6"
                style={{ borderTop: '1px solid #747486' }}
              />
              <StakingStage
                title="Approve SPO"
                description="You first need to approve the spending of your SPO token"
                loading={isApprovedLoading}
                success={isApproved}
                error={isApprovedFailed}
                handleRetry={async () => {
                  if (!amount) return;
                  const isOk = await approveToken(amount);
                  if (!isOk) return;
                  stakeToken(amount);
                }}
              />
              <StakingStage
                title="Stake SPO"
                description="Confirm to stake your desired amount into SPO Staking pool"
                loading={isStakedLoading}
                success={isStaked}
                error={isStakedFailed}
                handleRetry={() => amount && stakeToken(amount)}
              />
              {isApproved && isStaked && (
                <Button
                  className="rounded-full"
                  fullWidth
                  onClick={() => {
                    onClosePopDone();
                  }}
                >
                  DONE
                </Button>
              )}
              <IconButton
                className="p-0 mt-2"
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                }}
                onClick={() => onClosePopDone()}
              >
                <CancelOutlined className="text-[#A2A2AF] w-[24px] h-[24px]" />
              </IconButton>
            </div>
          </div>
          <div>
            <Paper>
              <ChangeNetworkPanels
              rpcInfo={netwokAddToMetaMask}
              setIsShowChangeNetworkNo={setIsShowChangeNetworkNo}
              />
            </Paper>
          </div>
        </CustomDialog>
        </>
      )}
    </div>
  );
};
