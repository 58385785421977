import React from 'react';
import { Button } from '@mui/material';

type UnStakeConfirmationProps = {
  onClose: Function;
  onUnStake: Function;
};

const UnStakeConfirmation: React.FC<UnStakeConfirmationProps> = ({ onClose, onUnStake }) => {
  const onConfirm = () => {
    onUnStake();
    onClose();
  };
  return (
    <div className='text-center p-10 bg-[#2B2B31] rounded-2xl'>
      <div className='flex justify-center mb-[22px]'>
        <img src='/assets/imgs/xl-icons/icon-warning.png' />
      </div>

      <div className='font-bold text-white text-2xl mb-4'>Unstake?</div>

      <div className='text-[#E8E8EB] max-w-[280px] mb-8'>
        <span className='font-bold'>Be aware:</span> After unstaked, your VIP level will be recalculated based on the
        remaining staked amount.
      </div>

      <div className='flex gap-2'>
        <Button fullWidth className='' onClick={onConfirm}>
          Confirm
        </Button>
        <Button fullWidth className='bg-[#36363F] text-white' onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default UnStakeConfirmation;
