import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { styled } from '@mui/system';

import './style.scss';

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  color: '#8B8B9B !important',
  '& .MuiAccordionSummary-content.Mui-expanded': {
    color: '#FFFFFF',
  },
}));

const dataList = [
  {
    question: '1. What is SPO staking pool?',
    answer: (
      <div>
        <div>
          We offer the staking pools that allows for eligibility to participate in IDOs on Spores Launchpad. Your staked
          amount across all pools will be automatically calculated to determine your VIP level at the time you
          participate in IDOs.
        </div>
        <ul className='list-disc list-inside ml-5'>
          <li>Users may stake SPO during the period of time designated for staking.</li>
          <li>Rewards will be calculated based on the locked up term of the pool.</li>
        </ul>
        <br />
        <div>Note: After you unstake, your VIP level will be recalculated based on the remaining staked amount.</div>
      </div>
    ),
  },
  {
    question: '2. How to stake?',
    answer: (
      <div>
        <div>Just follow these simple steps:</div>
        <ul className='list-disc list-inside ml-5'>
          <li>
            Step 1: Go to{' '}
            <a className='underline underline-offset-1' href='https://staking.spores.app/' target='_blank'>
              SPO staking page
            </a>
          </li>
          <li>Step 2: Connect your crypto wallet</li>
          <li>Step 3: Choose your desired pool then click "Stake" to open the popup</li>
          <li>Step 4: Input amount you want to stake, then click "Stake” button</li>
        </ul>
      </div>
    ),
  },
  {
    question: '3. How rewards are calculated?',
    answer: (
      <div>
        <ul className='list-disc list-inside'>
          <li>
            Rewards will be given to users based on the duration of time they keep their tokens locked in the contract.
          </li>
          <li>
            There are two return rate determined as follows:
            <ul className='list-disc list-inside ml-5'>
              <li>Early return for users who unstake immediately after the mandatory locking period.</li>
              <li>Maturity return for users who keep their tokens locked in the contract until maturity.</li>
            </ul>
          </li>
        </ul>
      </div>
    ),
  },
  {
    question: '4. Do I need to unstake/restake for each IDO?',
    answer: (
      <div>
        <Typography>
          No. You are eligible for any IDOs as long as your SPO remain staked. The amount staked will be matched to the
          current level system.
        </Typography>
      </div>
    ),
  },
  {
    question: '5. How will Spores VIP System be more beneficial to members?',
    answer: (
      <div>
        <div>
          All Spores VIP Tier System is entitled to Guaranteed Allocation. The more $SPO you staked and the higher your
          VIP tier is, you can secure higher allocation compared to lower levels.
        </div>
        <div>
          Everything is explained more detail{' '}
          <a
            className='underline underline-offset-1'
            href='https://spores.medium.com/spores-network-launches-new-launchpad-vip-tier-system-5444f8c5d74'
            target='_blank'
          >
            here
          </a>
          .
        </div>
      </div>
    ),
  },
  {
    question: (
      <>
        6. How can I participate in Spores upcoming IDO/INOs on{' '}
        <a className='underline underline-offset-4' href='https://launchpad.spores.app' target='_blank'>
          Spores Launchpad?{' '}
        </a>
      </>
    ),
    answer: (
      <div>
        <ul className='list-disc list-inside'>
          <li>Step 1: Stake a required amount of SPO</li>
          <li>Step 2: Register for the whitelist</li>
          <li>Step 3: Participate in the INOs/IDOs with assigned VIP level</li>
        </ul>
        <div className='ml-[20px] md:ml-[75px]'>
          <div>You can buy IDO in a different round:</div>
          <ul className='list-[circle] list-inside'>
            <li>VIP Round: Opened for VIPs only</li>
            <li>Triple Limit: Opened for VIPs only</li>
            <li>FCFS Round: Everyone can buy</li>
          </ul>
        </div>
      </div>
    ),
  },
  {
    question: <>7. How does the Tier system work? How does the Pool weight work?</>,
    answer: (
      <div>
        Each tier will be equivalent to the amount of $SPO staked. Based on the inventory/allocation for each project,
        we will allocate the VIP tier purchasing amount. The more $SPO you stake, the higher your tier will be and the
        more tokens you will be guaranteed to purchase.
      </div>
    ),
  },
];

export const FAQs: React.FC<{}> = () => {
  return (
    <div className='faqs-component'>
      <div className='text-white font-bold text-2xl mb-8'>FAQs</div>
      <div className='bg-[#2B2B31] rounded-2xl'>
        {dataList.map((item, index) => (
          <Accordion key={index}>
            <StyledAccordionSummary expandIcon={<ExpandMoreTwoToneIcon fontSize='large' sx={{ color: '#FFFFFF' }} />}>
              <div className='text-lg font-bold'>{item.question}</div>
            </StyledAccordionSummary>
            <AccordionDetails className='px-8 pb-10 pt-0'>
              <div className='rounded-2xl bg-[#191B1E] p-8 text-white'>{item.answer}</div>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};
