import { PoolType } from 'helpers';
import { useEffect, useState } from 'react';
import { TableList } from './table';
import { TableListMobile } from './table-mobile';
import { styled, Dialog } from '@mui/material';

import { DEPRECATED_POOL } from './data';
import './style.scss';
import { PopupStandardPool } from '../popup-standard-pool';

const CustomDialog = styled(Dialog)`
  .MuiPaper-root {
    background-color: transparent;
    width: 100%;
    @media (max-width: 500px) {
      margin: 0px;
    }
  }
`;

export const DeprecatedPoolsTable: React.FC<{}> = () => {
  const [poolsList, setPoolsList] = useState<PoolType[]>([]);
  const [isOpenPopupStandard, setIsOpenPopupStandard] = useState(false);
  const [chosenIndex, setChosenIndex] = useState(0);

  useEffect(() => {
    const closedPools = DEPRECATED_POOL.sort((a, b) => b.pool_name.localeCompare(a.pool_name));
    setPoolsList(closedPools);
  }, []);

  return (
    <div className="flex flex-col gap-8 items-center">
      <div
        className="rounded-2xl overflow-hidden w-full"
        style={{ border: '1px solid #45454f' }}
      >
        <div className="md:block hidden">
          <TableList
            poolsList={poolsList}
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
            setChosenIndex={(index: number) => setChosenIndex(index)}
          />
        </div>
        <div className="block md:hidden">
          <TableListMobile
            poolsList={poolsList}
            setIsOpenPopupStandard={(open: boolean) =>
              setIsOpenPopupStandard(open)
            }
            setChosenIndex={(index: number) => setChosenIndex(index)}
          />
        </div>
      </div>
      {isOpenPopupStandard && (
        <CustomDialog maxWidth="md" open={isOpenPopupStandard}>
          <PopupStandardPool
            handleClose={() => setIsOpenPopupStandard(false)}
            poolData={poolsList[chosenIndex]}
          />
        </CustomDialog>
      )}
    </div>
  );
};
