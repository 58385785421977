import { API_URL } from 'env';
import { configurableFetch } from 'utils/configurable-fetch';
import { getAccessToken } from '../../utils/auth';
import { Configuration } from '../generated-api';

export const getApiConfig = (accessToken?: string): Configuration | any => {
  accessToken =
    accessToken || typeof window !== 'undefined' ? getAccessToken() : undefined;
  return {
    basePath: API_URL,
    fetchMethod: configurableFetch,
    ...(accessToken
      ? {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        }
      : {}),
  };
};
