import { formatNumber } from 'utils';
import classNames from './vip-tiers.module.scss';

export const VipTier: React.FC<{
  vipTierData: any;
}> = ({ vipTierData = {} }) => (
  <div className={classNames.column}>
    <div className={[classNames.box_firstRow, classNames.box_column].join(' ')}>
      <img src={vipTierData?.img} alt={vipTierData?.name} width={35} height={40} />
      <span className='text-xl font-bold uppercase'>{vipTierData?.name}</span>
    </div>

    <div className={classNames.box_row}>{formatNumber(vipTierData?.amount)}</div>

    <div className={classNames.box_row}>{formatNumber(vipTierData?.pool_weight_min)}</div>
  </div>
);
