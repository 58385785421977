import { PoolType } from "helpers";
import { useSelector } from 'react-redux';
import { Tooltip, IconButton, LinearProgress, styled } from "@mui/material";
import { ScrollingText } from "components";
import { getUserData } from "reducers/allPoolInfoAction";

const CustomProgress = styled(LinearProgress)`
  background: #191b1e;
  height: 8px;
  border-radius: 100px;
  .MuiLinearProgress-bar {
    border-radius: 100px;
    background: #de6a2f;
  }
`;

const getVipLevel = (vipTiersData: any[], stakedAmount: number) => {
  const convertedVipTiersData = vipTiersData.map((vipTierData) => {
    return { ...vipTierData, amount: Number(vipTierData.amount) };
  });
  convertedVipTiersData.sort((a, b) => a.amount - b.amount);

  let currentVip = null;
  let nextVip = null;
  for (const convertedVipTierData of convertedVipTiersData) {
    if (stakedAmount >= convertedVipTierData.amount) {
      currentVip = convertedVipTierData;
    } else {
      nextVip = convertedVipTierData;
      break;
    }
  }

  return [currentVip, nextVip];
};

export const PrivateUnstakePanel: React.FC<{
  poolData?: PoolType;
}> = ({ poolData }) => {
  const { isLoggedIn } = useSelector<any, any>(
    ({ profile }) => profile,
  );
  const { vipTiersData, allPoolInfo } = useSelector<any, any>(({ allPoolInfoSlice }) => allPoolInfoSlice);

  const stakedAmount = allPoolInfo?.amount ?? 0
  const [currentVip, nextVip] = getVipLevel(vipTiersData, stakedAmount ?? 0);

  return (
    <div className="text-[#A2A2AF]">
      <div className='flex justify-between mb-4'>
        <div className='text-sm'>Current staking</div>
        <div className='text-white font-bold'>
          {isLoggedIn ? `${stakedAmount.toLocaleString()} ${poolData?.token_name}` : '-'}
        </div>
      </div>
      <div className='flex justify-between mb-4'>
        <div className='flex gap-2 items-center'>
          <div className='text-sm'>Current level</div>
          <Tooltip placement='top' title={<div>Reload</div>}>
            <IconButton className='hover:bg-[#36363F] rounded-lg px-2 py-1' onClick={getUserData}>
              <img src='/assets/imgs/xl-icons/icon-reload.png' className='w-3 h-3' />
            </IconButton>
          </Tooltip>
        </div>
        <div className='text-white font-bold flex gap-2 items-center'>
          <img
            className='w-auto h-6'
            src={currentVip ? currentVip.imgXl : '/assets/imgs/tier-system-xl/default.png'}
            alt='vip level'
          />
          {currentVip?.name ?? '-'}
        </div>
      </div>
      <div>
        <CustomProgress
          variant='determinate'
          value={!!nextVip ? ((allPoolInfo?.amount ?? 0) * 100) / nextVip?.amount : 100}
        />
      </div>
      <div className='flex justify-between mb-4 text-sm'>
        <div>{`Next: ${isLoggedIn && nextVip ? nextVip?.name : '-'}`}</div>
        <div className='text-right'>
          <ScrollingText
            text1={
              isLoggedIn
                ? `${Number(allPoolInfo?.amount ?? 0).toLocaleString()}/${(nextVip?.amount ?? 0).toLocaleString()}`
                : '-/-'
            }
            text2={
              isLoggedIn
                ? `${((nextVip?.amount ?? 0) - Number(allPoolInfo?.amount ?? 0)).toLocaleString()} SPO left`
                : '- SPO left'
            }
          />
        </div>
      </div>
    </div>
  );
};
