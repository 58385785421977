import { store } from 'reducers';
import { updateNetworkInfoData } from './networkInfoSlice';
import { getSystemConfigApi } from 'swagger';
// import privateSummerSale from '../data/private_summer_sale.json'

export const getNetworkData = async () => {
  try {
    const { data } = await getSystemConfigApi()
      .apiSystemConfigRpcGet()
      .then((res) => res.json());
    store.dispatch(
      updateNetworkInfoData(data),
    );
  } catch (e) {
    console.log(e);
  }
};
