import { CircularProgress, Typography } from '@mui/material';

const ProcessingPopup = () => {
  return (
    <div
      className="box-border text-center p-10"
      style={{ maxWidth: '380', backgroundColor: '#2B2B31' }}
    >
      <CircularProgress sx={{ color: '#EECE7C', fontSize: 30}} thickness={5} />
      <Typography variant="h3" className="my-4">
        Processing!
      </Typography>
      <Typography className="mt-4">
        It may take some minutes to complete the transaction.
      </Typography>
    </div>
  );
};

export default ProcessingPopup;
