import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePopover } from 'hooks';
import {
  AppBar,
  Button,
  IconButton,
  Link as MuiLink,
  ListItemButton,
  MenuList,
  Modal,
  Popover,
  Toolbar,
} from '@mui/material';
import { connectWallet, config } from 'reducers/profileAction';
import { signOut } from 'reducers/profileSlice';
import { shorten } from 'utils/common';
import { Close, Menu as MenuIcon } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { NetworkBar } from 'components';
import { getData, getUserData } from 'reducers/allPoolInfoAction';
import { reconnect, disconnect } from '@wagmi/core';

const itemList = [
  { text: 'Launchpad', url: 'https://launchpad.spores.app/', isAt: false },
  { text: 'Staking', url: '/', isAt: true },
  {
    text: 'Get SPO',
    url: 'https://pancakeswap.finance/swap?outputCurrency=0x8357c604c5533fa0053beaaa1494da552cea38f7',
    isAt: false,
  },
];

reconnect(config);
// const { isConnected } = getAccount(config);

const Header = () => {
  const dispatch = useDispatch();
  const { isLoggedIn, address } = useSelector(({ profile }) => profile);

  const [isOpen, anchor, onClick, onClose] = usePopover();
  const [openPopup, setOpenPopup] = useState(false);
  
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getUserData();
  }, [isLoggedIn]);

  return (
    <div className='relative flex justify-center bg-[#262627]'>
      <AppBar position='sticky' elevation={0} className='max-w-[1280px] bg-[#262627]'>
        <Toolbar>
          <div className='flex-none'>
            <Link to='/'>
              <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-7 sm:h-10' />
            </Link>
            {<NetworkBar />}
          </div>
          <div className='sm:hidden flex-1 flex items-center justify-end'>
            <IconButton onClick={() => setOpenPopup(true)}>
              <MenuIcon className='text-white' />
            </IconButton>

            <Modal
              open={openPopup}
              onClose={() => setOpenPopup(false)}
              style={{ background: 'black' }}
              className='sm:hidden'
            >
              <div className='h-full'>
                <div
                  className='sticky top-0 flex justify-between items-center py-4 px-4 z-50'
                  style={{
                    background: '#2B2B31',
                    backdropFilter: 'blur(15px)',
                  }}
                >
                  <div className='flex-none'>
                    <Link to='/'>
                      <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-7' />
                    </Link>
                  </div>
                  <IconButton onClick={() => setOpenPopup(false)}>
                    <Close className='text-white h-7' />
                  </IconButton>
                </div>
                <div className='px-7 overflow-auto h-full pt-5 pb-80'>
                  <MenuList className='flex flex-col gap-7 bg-black'>
                    {itemList.map((item, index) => (
                      <div key={index}>
                        <MuiLink href={item.url} target={item.isAt ? '_self' : '_blank'}>
                          <div
                            className={`text-[${item.isAt ? '#EECE7C' : '#F5F8FF'}] font-bold text-center text-[28px]`}
                          >
                            {item.text}
                          </div>
                        </MuiLink>
                      </div>
                    ))}
                  </MenuList>
                </div>
                <div
                  className='sticky bottom-0 text-color-secondary text-lg font-extrabold z-50'
                  style={{ background: '#2B2B31', backdropFilter: 'blur(8px)' }}
                >
                  <div
                    className='px-6 py-5'
                    style={{
                      borderTop: '1px solid #463024',
                      borderBottom: '1px solid #463024',
                    }}
                  >
                    {isLoggedIn ? (
                      <>
                        <Button
                          fullWidth
                          startIcon={<img src='assets/imgs/logo-bsc-dark.png' className='h-6' />}
                          variant='text'
                          className='text-[#F5F8FF] text-base font-bold mb-2'
                          style={{
                            backgroundColor: '#33363D',
                            border: '1px solid #6C707A',
                            borderRadius: 20,
                            padding: '16px 0px',
                          }}
                          onClick={onClick}
                        >
                          {shorten(address)}
                        </Button>
                        <Button
                          fullWidth
                          variant='text'
                          className='text-[#F53C5B] text-base font-semibold'
                          style={{
                            padding: '8px 0px',
                          }}
                          onClick={async () => {
                            disconnect(config);
                            // await disconnect(config);
                            dispatch(signOut());
                          }}
                        >
                          Disconnect
                        </Button>
                      </>
                    ) : (
                      <Button fullWidth onClick={connectWallet}>
                        Connect wallet
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Modal>
          </div>
          <div className='hidden flex-1 sm:flex gap-10 items-center justify-end'>
            {itemList.map((item, index) => (
              <div key={index}>
                <MuiLink href={item.url} target={item.isAt ? '_self' : '_blank'}>
                  <div className={`text-[${item.isAt ? '#EECE7C' : '#F5F8FF'}] hover:text-[#EECE7C]`}>{item.text}</div>
                </MuiLink>
              </div>
            ))}

            <div className='flex items-center'>
              {!isLoggedIn ? (
                <Button onClick={connectWallet}>Connect wallet</Button>
              ) : (
                <div>
                  <Button
                    startIcon={<img src='assets/imgs/logo-bsc-dark.png' className='h-6 sm:h-6' />}
                    size='small'
                    variant='text'
                    className='text-gray-300 text-sm px-4 bg-[#33363D] hover:bg-[#4f535a]'
                    style={{
                      border: '1px solid #6C707A',
                      borderRadius: 20,
                      padding: '7px 13px',
                    }}
                    onClick={onClick}
                  >
                    {shorten(address)}
                  </Button>
                  <Popover open={isOpen} anchorEl={anchor} onClose={onClose}>
                    <ListItemButton
                      style={{ backgroundColor: '#3C3C3E' }}
                      className='text-sm'
                      onClick={() => {
                        onClose();
                        dispatch(signOut());
                      }}
                    >
                      Disconnect
                    </ListItemButton>
                  </Popover>
                </div>
              )}
            </div>
          </div>
        </Toolbar>
      </AppBar>
      {/* <SubscribeEmail /> */}
    </div>
  );
};

export default Header;
