export const maturityReturn = (estApr: any[]) => {
  const maturityRewardReturnObject = estApr?.reduce((accumulator, currentValue) => {
    if (!accumulator) return currentValue;
    return accumulator.time > currentValue.time ? accumulator : currentValue;
  }, undefined);

  return maturityRewardReturnObject;
};

export const earlyRewardReturn = (estApr: any[]) => {
  const earlyRewardReturnObject = estApr?.reduce((accumulator, currentValue) => {
    if (!accumulator) return currentValue;
    return accumulator.time < currentValue.time ? accumulator : currentValue;
  }, undefined);

  return earlyRewardReturnObject;
};
