import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Link as MuiLink, Toolbar } from '@mui/material';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(
  {
    footer: {},
    '@media (max-width: 767px)': {
      footer: {
        flexDirection: 'column',
      },
    },
  },
  {
    name: 'LayoutFooter',
  },
);

const Title = ({ text }) => <div className='text-xl font-semibold text-[#F5F8FF] mb-7'>{text}</div>;

const productList = [
  { text: 'Launchpad', url: 'https://launchpad.spores.app' },
  { text: 'Staking', url: '' },
  // { text: 'Earning', url: 'https://staking-v1.spores.app/' },
  // { text: 'Marketplace', url: 'https://marketplace.spores.app' },
  // { text: 'République', url: 'https://republique.spores.app/' },
  // { text: 'Gamestore', url: 'https://games.spores.app' },
];

const helpList = [
  // { text: 'FAQs', url: '' },
  {
    text: 'Apply to Launch',
    url: 'https://docs.google.com/forms/d/1K_GPDM6RmYbz60GWyEy9IKXUyEGBrRqlTnmqU8Gd1cw/viewform?edit_requested=true#settings',
  },
  // { text: 'About', url: '/about' },
  // {
  //   text: 'Docs',
  //   url: 'https://spores-network.gitbook.io/spores-network-white-paper-2.0/',
  // },
  // { text: 'News', url: '' },
  {
    text: 'Contact us',
    url: 'https://t.me/hoaha47',
    className: 'text-white px-3 py-1 rounded border border-solid border-white hover:border-[#eece7c]',
  },
];

const contractList = [
  {
    text: 'Ethereum Contract: 0xcbE7713235...F08A7ACC4E',
    url: 'https://etherscan.io/token/0xcbE771323587EA16dACB6016e269D7F08A7ACC4E',
  },
  {
    text: 'BSC Contract: 0x8357c604c5...552cea38f7',
    url: 'https://bscscan.com/token/0x8357c604c5533fa0053beaaa1494da552cea38f7',
  },
];

const tokenList = [
  { image: 'uniswap', url: 'https://app.uniswap.org/#/swap?outputCurrency=0xcbE771323587EA16dACB6016e269D7F08A7ACC4E' },
  {
    image: 'pancakeswap',
    url: 'https://pancakeswap.finance/swap?outputCurrency=0x8357c604c5533fa0053beaaa1494da552cea38f7',
  },
  {
    image: 'gateio',
    url: 'https://www.gate.io/trade/SPO_USDT',
  },
  { image: 'cryptorank', url: 'https://cryptorank.io/fundraising-platforms/spores-network' },
];

const mediaList = [
  { image: 'medium', url: 'https://spores.medium.com/' },
  { image: 'instagram', url: 'https://instagram.com/SporesNetwork' },
  { image: 'discord', url: 'https://discord.com/invite/gm8dChbA' },
  { image: 'facebook', url: 'https://facebook.com/SporesNetwork' },
  { image: 'telegram', url: 'https://t.me/SporesGlobal' },
  { image: 'twitter', url: 'https://twitter.com/Spores_Network' },
  { image: 'linkedin', url: 'https://linkedin.com/company/SporesNetwork' },
];

const HoverLogo = ({ image }) => {
  const [realImage, setRealImage] = useState(image);

  return (
    <div
      className='cursor-pointer w-fit'
      onMouseEnter={() => setRealImage(`${image}-hover`)}
      onMouseLeave={() => setRealImage(image)}
    >
      <img src={`assets/imgs/xl-icons/${realImage}.png`} />
    </div>
  );
};

const HoverLogoMedia = ({ image }) => {
  const [color, setColor] = useState('#BDC2CC');

  return (
    <div
      className='cursor-pointer w-fit'
      onMouseEnter={() => setColor('#FFE89E')}
      onMouseLeave={() => setColor('#BDC2CC')}
    >
      <i
        className={`block w-6 h-6`}
        style={{
          WebkitMaskImage: `url(assets/imgs/media/${image}.png)`,
          WebkitMaskSize: '100%',
          background: color,
        }}
      />
    </div>
  );
};

const Footer = memo(() => {
  const classes = useStyles();

  return (
    <div className='relative flex justify-center bg-[#141416]'>
      <AppBar position='sticky' elevation={0} className='max-w-[1280px] bg-[#141416]'>
        <Toolbar className={classes.footer}>
          <div className='py-10 w-full text-[#A2A8B3]'>
            <div className='flex flex-col sm:grid sm:grid-cols-7 gap-10 mb-8'>
              <div
                className='col-span-2 border-b-[1px] sm:border-b-[0px] sm:border-r-[1px] pb-10 sm:pb-0 pr-10'
                style={{ borderColor: '#33363D' }}
              >
                <Link to='/'>
                  <img src={require('assets/icons/logo-spores-invert.svg').default} className='h-10 mb-[18px]' />
                </Link>
                <div>
                  Spores Network is the leading multi-chain launchpad for IDOs and INOs of up-and-coming high quality
                  web3 projects, in which user can seamlessly participate & support the project!
                </div>
              </div>
              <div className='sm:col-span-5'>
                <div className='grid grid-cols-2 sm:grid-cols-4 gap-5 sm:gap-0'>
                  <div>
                    <Title text='Product' />
                    <div className='flex flex-col gap-2'>
                      {productList.map((item, index) => (
                        <div key={index} className='flex'>
                          <MuiLink href={item.url} target='_blank'>
                            <div className='hover:text-[#EECE7C] text-[#A2A8B3]'>{item.text}</div>
                          </MuiLink>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Title text='Token' />
                    <div className='flex flex-col gap-2'>
                      {tokenList.map((item, index) => (
                        <div key={index} className='flex'>
                          <MuiLink href={item.url} target='_blank'>
                            <HoverLogo image={item.image} />
                          </MuiLink>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Title text='Help Center' />
                    <div className='flex flex-col gap-2'>
                      {helpList.map((item, index) => (
                        <div key={index} className='flex'>
                          <MuiLink href={item.url} target='_blank'>
                            <div className='hover:text-[#EECE7C] text-[#A2A8B3]'>{item.text}</div>
                          </MuiLink>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Title text='Contact' />
                    <div className='grid grid-cols-4 gap-6 max-w-[168px]'>
                      {mediaList.map((item, index) => (
                        <div key={index}>
                          <MuiLink href={item.url} target='_blank'>
                            <HoverLogoMedia image={item.image} />
                          </MuiLink>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className='w-full'>
                  <ChangeNetworkPanels
                    rpcInfo={netwokAddToMetaMask}
                    setIsShowChangeNetworkNo={0}
                  />
                </div> */}
              </div>
            </div>
            <div
              className='pt-8 break-words flex flex-col sm:flex-row gap-4 sm:justify-between text-sm'
              style={{ borderTop: '1px solid #33363D' }}
            >
              <div className='flex flex-col sm:flex-row gap-4 sm:gap-10'>
                {contractList.map((item, index) => (
                  <div key={index}>
                    <MuiLink href={item.url} target='_blank'>
                      <div className='text-[#A2A8B3] hover:text-[#EECE7C]'>{item.text}</div>
                    </MuiLink>
                  </div>
                ))}
              </div>
              <div>customersupport@spores.app</div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
});

export default Footer;
