import React from 'react';

const usePopup: (open?: boolean) => [boolean, () => void, () => void] = (
  open = false,
) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(!!open);
  
  const onOpen = React.useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, onOpen, onClose];
};

export default usePopup;
