export const shortenNumber = (num: number): string  => {
  if (num >= 1_000_000_000) {
    const formattedNumber = (num / 1_000_000_000).toFixed(1);
    return formattedNumber.endsWith('.0') ? `${parseInt(formattedNumber)}B` : `${formattedNumber}B`;
  } else if (num >= 1_000_000) {
    const formattedNumber = (num / 1_000_000).toFixed(1);
    return formattedNumber.endsWith('.0') ? `${parseInt(formattedNumber)}M` : `${formattedNumber}M`;
  } else if (num >= 1_000) {
    const formattedNumber = (num / 1_000).toFixed(1);
    return formattedNumber.endsWith('.0') ? `${parseInt(formattedNumber)}K` : `${formattedNumber}K`;
  } else {
    return num.toString();
  }  
}